import React, { useState } from "react"
import PropTypes from "prop-types"

// --------------------------------------------------------

import Card from "@components/card"
import Button from "@components/button"

// --------------------------------------------------------

import { container, cards_container, button_container, header } from "./styles.module.scss"

const LIMIT = 12

const CardGrid = ({ data, parent, cardLabel, title }) => {
  const posts = data?.items

  const [endPost, setEndPost] = useState(LIMIT)
  const pagePosts = posts?.slice(0, endPost)

  // ---------------------------------------------------------

  const loadMore = () => {
    setEndPost(endPost + LIMIT)
  }

  // ---------------------------------------------------------

  return (
    <>
      {pagePosts?.length > 0 && (
        <div className={container}>
          <h2 className={header}>{title}</h2>
          <div className={cards_container}>
            {pagePosts.map((post, index) => {
              let buttonUrl = post.link || `/${parent}/${post.slug}`
              if (post) {
                return (
                  <div key={index}>
                    <Card
                      key={index}
                      theme="hover"
                      title={post.title}
                      description={post.excerpt}
                      image={{
                        alt: post.featuredImage.description,
                        src: post.featuredImage.url,
                        height: post.featuredImage.height,
                        width: post.featuredImage.width
                      }}
                      startDate={post.startDate || post.date}
                      label={cardLabel || post.type || post.resourceType}
                      buttonUrl={buttonUrl}
                      duration={post.duration}
                    />
                  </div>
                )
              }
              return null
            })}
          </div>
        </div>
      )}
      {posts?.length > LIMIT && (
        <div className={button_container}>
          {endPost < posts?.length - 1 && (
            <Button theme="dark" onClick={loadMore}>
              Show more
            </Button>
          )}
        </div>
      )}
    </>
  )
}

CardGrid.propTypes = {
  /**
   * The cards that will be shown on the page
   */
  data: PropTypes.object,

  /**
   * The title of the page
   */
  pageTitle: PropTypes.string,

  /**
   * sets the url structure for links on the site
   */
  parent: PropTypes.string,

  /**
   * Specifies the label that should appear on the cards in the grid
   */
  cardLabel: PropTypes.string,

  /**
   * The title of the card grid section
   */
  title: PropTypes.string
}

export default CardGrid
