import React from "react"
import PropTypes from "prop-types"

// ---------------------------------------------------------

import CardGrid from "@templates/card-grid"

// ---------------------------------------------------------

const DemoCards = ({ demoData }) => {
  return (
    <>
      <CardGrid
        data={demoData}
        cardLabel="Demo"
        parent="resources/demo"
        title="Demos by solution"
      />
    </>
  )
}

DemoCards.propTypes = {
  /**
   * All demo resource data to be displayed in cards.
   */
  demoData: PropTypes.object
}

export default DemoCards
